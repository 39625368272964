<template>
  <g>
    <svg:style>
       .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
      .cls-2 {
        fill: url(#inox-gradient);
      }

      .cls-3 {
        fill: url(#glass-pattern);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: none;
      }
    </svg:style>

    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 71.08" 
      :y1="doorTopHeight1 + 247.72" 
      :x2="doorLeftWidth1 + 71.08" 
      :y2="doorTopHeight1 + 54.19" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="R04">
      <polyline id="inox" v-if="showInox"
                data-name="inox"
                class="cls-2"
                :points="`${doorLeftWidth1 + 53.34} ${doorTopHeight1 + 55.55} ${doorLeftWidth1 + 53.34} ${doorTopHeight1 + 246.36} ${doorLeftWidth1 + 53.61} ${doorTopHeight1 + 247.19} ${doorLeftWidth1 + 54.3} ${doorTopHeight1 + 247.71} ${doorLeftWidth1 + 55.17} ${doorTopHeight1 + 247.72} ${doorLeftWidth1 + 55.89} ${doorTopHeight1 + 247.24} ${doorLeftWidth1 + 70} ${doorTopHeight1 + 225.54} ${doorLeftWidth1 + 80.36} ${doorTopHeight1 + 201.83} ${doorLeftWidth1 + 86.69} ${doorTopHeight1 + 176.75} ${doorLeftWidth1 + 88.82} ${doorTopHeight1 + 150.96} ${doorLeftWidth1 + 86.69} ${doorTopHeight1 + 125.17} ${doorLeftWidth1 + 80.36} ${doorTopHeight1 + 100.08} ${doorLeftWidth1 + 70} ${doorTopHeight1 + 76.37} ${doorLeftWidth1 + 55.89} ${doorTopHeight1 + 54.69} ${doorLeftWidth1 + 55.17} ${doorTopHeight1 + 54.19} ${doorLeftWidth1 + 54.3} ${doorTopHeight1 + 54.22} ${doorLeftWidth1 + 53.61} ${doorTopHeight1 + 54.73} ${doorLeftWidth1 + 53.34} ${doorTopHeight1 + 55.55}`"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-3"
                :points="`${doorLeftWidth1 + 58.31} ${doorTopHeight1 + 71.38} ${doorLeftWidth1 + 58.31} ${doorTopHeight1 + 141.38} ${doorLeftWidth1 + 58.51} ${doorTopHeight1 + 142.09} ${doorLeftWidth1 + 59.02} ${doorTopHeight1 + 142.61} ${doorLeftWidth1 + 59.73} ${doorTopHeight1 + 142.8} ${doorLeftWidth1 + 82.13} ${doorTopHeight1 + 142.8} ${doorLeftWidth1 + 82.86} ${doorTopHeight1 + 142.6} ${doorLeftWidth1 + 83.39} ${doorTopHeight1 + 142.04} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 141.29} ${doorLeftWidth1 + 79.92} ${doorTopHeight1 + 116.61} ${doorLeftWidth1 + 72.32} ${doorTopHeight1 + 92.84} ${doorLeftWidth1 + 60.93} ${doorTopHeight1 + 70.64} ${doorLeftWidth1 + 60.24} ${doorTopHeight1 + 70.07} ${doorLeftWidth1 + 59.34} ${doorTopHeight1 + 70.03} ${doorLeftWidth1 + 58.59} ${doorTopHeight1 + 70.53} ${doorLeftWidth1 + 58.31} ${doorTopHeight1 + 71.38}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2"
                data-name="glass"
                class="cls-4"
                :points="`${doorLeftWidth1 + 82.13} ${doorTopHeight1 + 159.12} ${doorLeftWidth1 + 59.73} ${doorTopHeight1 + 159.12} ${doorLeftWidth1 + 59.02} ${doorTopHeight1 + 159.31} ${doorLeftWidth1 + 58.51} ${doorTopHeight1 + 159.83} ${doorLeftWidth1 + 58.31} ${doorTopHeight1 + 160.54} ${doorLeftWidth1 + 58.31} ${doorTopHeight1 + 230.53} ${doorLeftWidth1 + 58.59} ${doorTopHeight1 + 231.38} ${doorLeftWidth1 + 59.34} ${doorTopHeight1 + 231.9} ${doorLeftWidth1 + 60.24} ${doorTopHeight1 + 231.86} ${doorLeftWidth1 + 60.93} ${doorTopHeight1 + 231.28} ${doorLeftWidth1 + 72.32} ${doorTopHeight1 + 209.08} ${doorLeftWidth1 + 79.92} ${doorTopHeight1 + 185.32} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 160.63} ${doorLeftWidth1 + 83.39} ${doorTopHeight1 + 159.89} ${doorLeftWidth1 + 82.86} ${doorTopHeight1 + 159.33} ${doorLeftWidth1 + 82.13} ${doorTopHeight1 + 159.12}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3"
                :fill="fill"
                data-name="glass"
                class="cls-5"
                :points="`${doorLeftWidth1 + 83.85} ${doorTopHeight1 + 150.96} ${doorLeftWidth1 + 81.83} ${doorTopHeight1 + 149.24} ${doorLeftWidth1 + 76.38} ${doorTopHeight1 + 148.05} ${doorLeftWidth1 + 69.26} ${doorTopHeight1 + 147.81} ${doorLeftWidth1 + 62.72} ${doorTopHeight1 + 148.55} ${doorLeftWidth1 + 58.83} ${doorTopHeight1 + 150.06} ${doorLeftWidth1 + 58.83} ${doorTopHeight1 + 151.86} ${doorLeftWidth1 + 62.72} ${doorTopHeight1 + 153.38} ${doorLeftWidth1 + 69.26} ${doorTopHeight1 + 154.12} ${doorLeftWidth1 + 76.38} ${doorTopHeight1 + 153.86} ${doorLeftWidth1 + 81.83} ${doorTopHeight1 + 152.69} ${doorLeftWidth1 + 83.85} ${doorTopHeight1 + 150.96}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'fill',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
